import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'main-button',
  templateUrl: './main-button.component.html'
})
export class MainButtonComponent {
  @Input() buttonText: string = 'Click here';
  @Input() disabledButton: boolean = false;
  @Output() sendEvent: EventEmitter<void> = new EventEmitter<void>();

  emitEvent(): void {
    this.sendEvent.emit();
  }
}
