<div class="text-center">
    <img [src]="imageMainLogo" width="120">
</div>
<div class="text-center mt-3">
    <span class="fw-bold fs-5">Reestablecer contraseña</span>
</div>
<div class="text-center">
    <span class="suptitle-class">Ingresa el código de validación que hemos enviado al correo {{ hideEmail(email)
        }}</span>
</div>

<div class="mt-4 mb-1 space-around-cube">
    <input class="cube-number" id="field0" type="text" [(ngModel)]="code[0]" (input)="onCodeInput($event, 0)"
        maxlength="1" pattern="[0-9]*" />

    <input class="cube-number" id="field1" type="text" [(ngModel)]="code[1]" (input)="onCodeInput($event, 1)"
        maxlength="1" pattern="[0-9]*" />

    <input class="cube-number" id="field2" type="text" [(ngModel)]="code[2]" (input)="onCodeInput($event, 2)"
        maxlength="1" pattern="[0-9]*" />

    <input class="cube-number" id="field3" type="text" [(ngModel)]="code[3]" (input)="onCodeInput($event, 3)"
        maxlength="1" pattern="[0-9]*" />
</div>
<div class="mb-3 container-error">
    <span *ngIf="invalidCode">Código inválido</span>
</div>

<div class="text-center mb-1">
    <main-button buttonText="Validar código" [disabledButton]="!codeFull" (sendEvent)="validateCode()"></main-button>
</div>
<div class="mb-2 text-code-not" (click)="resendCode()" role="button">
    <span>¿No recibiste el código? <span class="text-code-not-color">Reenviar</span></span>
</div>