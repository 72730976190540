<nav class="navbar shadow-sm bg-white navbar-expand-lg navbar-expand-md py-3 pe-4 rounded-bottom-4">
    <div class="container-fluid">
        <a class="navbar-brand" href="#">
            <img [src]="imageMainLogo" alt="Logo" width="88px"
                class="d-inline-block align-text-top ms-4">
        </a>

        <div>
            <div class="d-flex flex-row justify-content-end align-items-center gap-4">                
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div class="ms-auto d-flex gap-3">
                    <!-- DROPDOWN USER -->
                    <div class="dropdown">
                        <div class="container d-flex flex-row gap-3 justify-content-start align-items-center"
                            role="button" data-bs-toggle="dropdown">                            
                            <div class="d-flex flex-column align-items-start justify-content-center">
                                <p class="fw-bold text-wrap text-break mb-0 p-0" style="font-size: 17px;">
                                    {{ user.displayName || '' }}
                                </p>
                                <p class="fw-light text-wrap text-break mb-0 p-0" style="font-size: 15px;">
                                    {{ user.email }}
                                </p>
                            </div>
                            <div>
                                <img [src]="iconArrowDown" alt="" class="opacity-50">
                            </div>
                        </div>
                        <!-- VISUALIZAR EN VISTA MOVIL -->
                        <ul class="dropdown-menu dropdown-menu-end">                          
                            <li><a class="dropdown-item" role="button" (click)="signOut()">Cerrar sesión</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>
