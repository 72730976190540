import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  constructor() { }
  private intervalId: any; // Variable to store the interval ID


  startTimer(intervalInSeconds: number, callback: () => void): void {
    const intervalInMilliseconds = intervalInSeconds * 1000;
    this.intervalId = setInterval(callback, intervalInMilliseconds);
  }

  stopInterval(): void {
    clearInterval(this.intervalId); // stop interval
  }
}
