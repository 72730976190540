import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiHttpService } from 'src/app/core/http/api-http.service';
import { TreebuLedgerResponseInterface } from 'src/app/core/interfaces/treebu-token.interface';
import { NotificactionService } from 'src/app/core/notifications/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})

export class ForgotPasswordComponent{
  errorMessage: string = '';
  email: string = '';
  emailSent: Boolean = false;
  signInForm: FormGroup;

  constructor(
    private _apiHttpService: ApiHttpService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.signInForm = this.fb.group({
      email: new FormControl('', [Validators.email]),
    });
  }

  verifySendEmail() {
    if (!this.email) {
      NotificactionService.Swal.fire({
        icon: 'warning',
        title: 'Debe completar la información ingresada.',
      });
      return;
    }
    this.sendEmailRecoverPassword();
  }

  sendEmailRecoverPassword() {
    this._apiHttpService
      .post<TreebuLedgerResponseInterface>(
        `${environment.treebuCapital}/${environment.prefixBasic}/${environment.prefixTreebuLedgerUnauthenticated}/AwsSES/send-recover-password`,
        { email: this.email }
      )
      .subscribe(
        (result) => {
          if (result.data == 'Email sent successfully') {
            this.emailSent = true;
          } else {
            this.errorMessage = result.data;
          }
        },
        (err) => {
          this.errorMessage = err.data;
        }      
      );
  }

  navigateResetPassword() {
    this.emailSent = false;
    this.router.navigate(['/restore/validate-code'], {
      queryParams: { email: this.email },
    });
  }

  clearData(){
    this.errorMessage = '';
  }
}
