export const excludedEndpoints: ReadonlyArray<string> = [
    'api/treebu-ledger/v1/AwsSES/send-activation-otp',
    'api/treebu-ledger/v1/otp/validate',
    'api/treebu-ledger/v1/User/reset-password',
    'api/treebu-ledger/v1/User/U4Pls0R4BgQ5TOtfSNcn8Rno217dsf',
    'api/treebu-ledger/v1/User/metamap-attempt',
    'api/treebu-ledger/v1/User/phone-number',
    'api/treebu-ledger/v1/User/update-firebase-token-device-id',
    'api/treebu-ledger/v1/SystemParameter',
    'api/treebu-ledger-unauthenticated/v1/User/create-user-backoffice-password'
];
