<navbar></navbar>
<div class="container-fluid">
    <div class="row">
        <div class="col-auto h-100 border-3 border-black px-3 back-light">
            <sidebar></sidebar>
        </div>
        <div class="container vh-50 col px-3 py-4">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
