import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { AppMediaService } from 'src/app/core/media/app-media.service';
import { NotificactionService } from 'src/app/core/notifications/notification.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['sing-in.component.css']
})

export class SignInComponent{
  // Forms
  showPassword: boolean = false;
  signInForm: FormGroup;

  // Icons
  iconEye: string;
  iconCancel: string;

  imageLogo: string;

  constructor(private _authService: AuthService, private fb: FormBuilder) {
    this.imageLogo = AppMediaService.media.logos.mainLogo;
    this.iconEye = AppMediaService.media.icons.options.eye;
    this.iconCancel = AppMediaService.media.icons.options.cancel;

    this.signInForm = this.fb.group({
      email: new FormControl('', [Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(15),
        this.customPasswordValidator
      ]),
    });
  }

  customPasswordValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (!value) {
      return null;
    }
    
    const pattern = /.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-=].*/;
    if (!pattern.test(value)) {
      return { invalidPassword: true };
    }

    return null;
  }

  verifySignIn() {
    if (this.signInForm.invalid) {
      NotificactionService.Swal.fire({
        icon: 'warning',
        title: 'Debe completar la información ingresada.',
      });
      return;
    }
    this.signIn();
  }

  signIn() {
    this._authService.signIn(
      this.signInForm.value.email,
      this.signInForm.value.password
    );
  }
}
