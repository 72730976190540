export const environment = {
  production: false,
  mode:'Dev',
  treebuLedger: 'https://dev.treebucapital.com/api/treebu-ledger/v1',

  // Servers
  treebuHasura: 'https://dev.treebucapital.com',
  treebuCapital: 'https://dev.treebucapital.com',
  treebuCrypto: 'https://dev.treebucapital.com',
  prefixEncrypted: 'api/ms-encrypted/v1/Encrypted',
  prefixTreebuHasura: 'api/rest',
  prefixTreeCrypto: 'api/ms-crypto/v1',
  prefixTreeLedger: 'api/treebu-ledger/v1',
  prefixBasic: 'api',
  prefixTreebuLedgerUnauthenticated : 'treebu-ledger-unauthenticated/v1',

  // Media Files
  mediaSource: '/assets/images',

  // Firebase
  firebaseConfig: {
    apiKey: "AIzaSyCZq52CDcYp4tFd85kKomqj35KROmuYAME",
    authDomain: "treebu-dev-521b7.firebaseapp.com",
    projectId: "treebu-dev-521b7",
    storageBucket: "treebu-dev-521b7.appspot.com",
    messagingSenderId: "181466184352",
    appId: "1:181466184352:web:4b1680a9aaac266985e7c5"
  },

  timerRefreshToken : 3300 // seconds
}
