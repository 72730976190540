import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { environment } from "src/environments/environment";
import {
  Auth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendEmailVerification,
  User
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AppRoutesService } from '../routes/app-routes.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  UserData!: any;
  validateUserStatusURL = `${environment.treebuHasura}/${environment.prefixTreebuHasura}/getuserstatusdatabase`;
  userStatus: boolean = false;

  constructor(
    private auth: Auth,
    private router: Router,
    public ngZone: NgZone,
    private http: HttpClient
  ) {
    this.onAuthStateChanged();
  }

  onAuthStateChanged(): void {
    onAuthStateChanged(this.auth, (user: any) => {
      if (user) {
        this.UserData = user;
        localStorage.setItem('accessToken', this.UserData.accessToken);
      } else {
        localStorage.setItem('accessToken', 'null');
      }
    })
  }

  //get User
  //get Authenticated user from firebase
  getUserData() {
    if (localStorage.getItem('userData')) {
      const userData = localStorage.getItem('userData') || '';
      this.UserData = JSON.parse(userData);
      return this.UserData;
    }
    return false;
  }

  //get Authenticated user from Local Storage
  getAuthLocal() {
    const token = localStorage.getItem('accessToken')
    const user = JSON.parse(token as string);
    return user;
  }

  //Check wither User Is looged in or not
  get isLoggedIn(): boolean {
    const token = localStorage.getItem('accessToken')
    const user = JSON.parse(token as string);
    return user !== null ? true : false;
  }

  //Register Method
  Register(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password)
      .then((result: any) => {
        this.UserData = result.user;
        this.ngZone.run(() => {
          /* Call the SendVerificaitonMail() function when new user sign
       up and returns promise */
          this.sendEmailVerification()
          this.router.navigate(['/dashboard']);
        });
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  //Login Method
  signIn(email: string, password: string) {
    const expirationDate = new Date();
    return signInWithEmailAndPassword(this.auth, email, password)
        .then((result: any) => {          
          this.UserData = result.user;
          this.http.get(this.validateUserStatusURL + "?firebaseId=" + result.user.uid).subscribe((data: any) => {
          this.userStatus = data.v_hasura_users_bo[0].is_inactive
          if (this.userStatus == false) {
            localStorage.setItem('activeRoute', 'transactions');
            localStorage.setItem('userData', JSON.stringify(this.UserData));
            localStorage.setItem('accessToken', result.user.accessToken);
            this.ngZone.run(() => {
              this.router.navigate(['/admin/transactions']);
              window.location.reload();
            });
          }
          else {
            localStorage.setItem('userData', '');
            localStorage.setItem('accessToken', '');
            window.alert("User is inactive");
          }
        })
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  //Logout
  signOut() {
    localStorage.clear();
    signOut(this.auth).then(() => this.router.navigate(['/auth/sign-in']))
  }

  //login with Email or Facebook
  //Login with Google
  GoogleAuth() {
    return this.loginWithPopup(new GoogleAuthProvider());
  }

  //Login with Facebook
  //FacebookAuth() {
  //  return this.loginWithPopup(new FacebookAuthProvider());
  //}



  //Pop Up Provider
  loginWithPopup(provider: any) {
    return signInWithPopup(this.auth, provider).then(() => {
      this.router.navigate(['dashboard']);
    });
  }

  //Send Password Reset Email
  async sendPasswordResetEmails(email: string) {
    sendPasswordResetEmail(this.auth, email)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  //Send Email Verification
  sendEmailVerification() {
    return sendEmailVerification(this.auth.currentUser as User);
  }

 // Method to refresh Firebase token
  refreshFirebaseToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      const user = this.auth.currentUser;
      if (user) {
        user.getIdToken(/* forceRefresh */ true)
          .then((token: string) => {
            localStorage.setItem('accessToken', token);
            resolve(token);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject('No authenticated user');
      }
    });
  }
}
