<div class="auth-body">
    <div class="container vh-100 d-flex justify-content-center align-items-center">
        <div class="card p-5 col-sm-12 col-md-6 col-lg-4 rounded-4 shadow">
            <app-sign-in></app-sign-in>

            <div class="mt-2 text-center">
                <a class="fw-semibold text-decoration-none text-black" role="button" data-bs-toggle="modal"
                    data-bs-target="#recoverPassword">Recuperar contraseña</a>
            </div>
        </div>
    </div>
</div>



<!-- Modal -->
<div class="modal fade" id="recoverPassword" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="recoverPasswordLabel" aria-hidden="true">
    <app-forgot-password></app-forgot-password>
</div>