<div class="card w-100 rounded-4 border-main">
    <div class="card-body">
        <span class="card-title fw-semibold fs-5">{{ title }}</span>
        <div class="d-flex flex-row align-items-center text-wrap">
            <span class="card-title me-1 font-grotesk fw-semibold fs-2">
                {{ amount | currency: (currency.alias| uppercase) }}
            </span>
            <span style="font-size: 100%;">{{ currency.alias }}</span>
        </div>
    </div>
</div>