import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';

import { FormsModule } from '@angular/forms';
import { RestorePasswordComponent } from './pages/restore-password/restore-password.component';
import { RestoreComponent } from './restore.component';
import { RestoreRoutingModule } from './restore-routing.module';
import { RestoreValidateCodeComponent } from './pages/restore-validate-code/restore-validate-code.component';


@NgModule({
  declarations: [
    RestoreComponent,
    RestorePasswordComponent,
    RestoreValidateCodeComponent
  ],
  imports: [
    CommonModule,
    RestoreRoutingModule,
    FormsModule,

    // Project's Module
    SharedModule
  ]
})
export class RestoreModule { }
