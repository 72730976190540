import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { TimerService } from 'src/app/core/helpers/timer-services.service';
import { AppMediaService } from 'src/app/core/media/app-media.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {
  user: any;

  // Icons
  iconBell: string;
  iconArrowDown: string;

  // Images
  imageUser: string;
  imageMainLogo: string;

  constructor(private _authService: AuthService, private _timerService : TimerService) {
    this.iconBell = AppMediaService.media.icons.options.bell;
    this.iconArrowDown = AppMediaService.media.icons.options.arrowDown;
    this.imageUser = AppMediaService.media.images.user;
    this.imageMainLogo = AppMediaService.media.logos.mainLogo;
  }

  ngOnInit(): void {
    this.user = this._authService.getUserData();
  }

  signOut(): void {
    this._timerService.stopInterval();
    this._authService.signOut();
  }
}
