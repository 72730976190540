import { environment } from "src/environments/environment";

export class AppMediaConfig {
    public static imagesSource(media: string): string {
        return `${environment.mediaSource}/images/${media}`;
    }

    public static logoSource(media: string): string {
        return `${environment.mediaSource}/logos/${media}`;
    }

    public static backgroundSource(media: string): string {
        return `${environment.mediaSource}/backgrounds/${media}`;
    }

    public static iconsSource(media: string): string {
        return `${environment.mediaSource}/icons/${media}`;
    }
}