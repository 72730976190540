import { Component, EventEmitter, Output } from '@angular/core';
import { AppMediaService } from 'src/app/core/media/app-media.service';

@Component({
  selector: 'search-input',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent {

  filter: string = '';
  @Output() exportFilter = new EventEmitter<string>();

  iconSearch: string;

  constructor() {
    this.iconSearch = AppMediaService.media.icons.options.search;
  }

  search() {
    this.exportFilter.emit(this.filter);
  }
}
