import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiHttpService } from 'src/app/core/http/api-http.service';
import { AppMediaService } from 'src/app/core/media/app-media.service';
import { NotificactionService } from 'src/app/core/notifications/notification.service';
import { AppRoutesService } from 'src/app/core/routes/app-routes.service';

@Component({
  selector: 'app-assign',
  templateUrl: './assign.component.html',
  styleUrls: ['./assign.component.css'],
})
export class AssignComponent {
  newPassToggle: boolean = false;
  repeatPassToggle: boolean = false;
  email: string = '';

  assignPasswordForm: FormGroup;

  showPassword: boolean = false;

  // Icons
  iconCancel: string;
  iconEye: string;

  // Images
  imageMainLogo: string;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _apiHttpService: ApiHttpService,
    private fb: FormBuilder,
    private route: Router
  ) {
    this.iconCancel = AppMediaService.media.icons.options.cancel;
    this.iconEye = AppMediaService.media.icons.options.eye;
    this.imageMainLogo = AppMediaService.media.logos.mainLogo;
    this.email = this._activatedRoute.snapshot.queryParamMap.get('email') || '';

    this.assignPasswordForm = this.fb.group({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(15),
        this.customPasswordValidator,
      ]),
      verifyPassword: new FormControl('', [Validators.required]),
    });
  }

  customPasswordValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (!value) {
      return null; 
    }

    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/ ;

    if (!pattern.test(value)) {
      return { invalidPassword: true };
    }

    return null; 
  }

  verifyPassword() {
    if (
      !this.assignPasswordForm.value.password ||
      !this.assignPasswordForm.value.verifyPassword
    ) {
      NotificactionService.Swal.fire({
        icon: 'warning',
        title: 'Validar la información ingresada.',
        text: 'Debe completar los campos.',
      });
      return;
    }

    if (
      this.assignPasswordForm.value.password !=
      this.assignPasswordForm.value.verifyPassword
    ) {
      NotificactionService.Swal.fire({
        icon: 'warning',
        title: 'Validar la información ingresada.',
        text: 'Las contraseñas no coinciden.',
      });
      return;
    }

    if (!this.email) {
      NotificactionService.Swal.fire({
        icon: 'warning',
        title: 'Validar la información ingresada.',
        text: 'No se encontró el correo electrónico.',
      });
      return;
    }

    this.setPassword();
  }

  setPassword() {  
    this._apiHttpService
      .post(AppRoutesService.auth.setPassword, {
        email: this.email,
        password: this.assignPasswordForm.value.password,
      })
      .subscribe(
        (result) => {
          NotificactionService.Swal.fire({
            icon: 'success',
            title: 'Se asignado la contraseña correctamente.',
          });

          this.route.navigate(['/auth/sign-in']);           
        },
        (err) =>
          NotificactionService.Swal.fire({
            icon: 'error',
            title: err,
          })
      );
  }

  toggleNewPassword() {
    this.newPassToggle = !this.newPassToggle;
  }
  toggleRepeatPassword() {
    this.repeatPassToggle = !this.repeatPassToggle;
  }
}
