import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiHttpService } from 'src/app/core/http/api-http.service';
import { TreebuLedgerResponseInterface } from 'src/app/core/interfaces/treebu-token.interface';
import { AppMediaService } from 'src/app/core/media/app-media.service';
import { NotificactionService } from 'src/app/core/notifications/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.css'],
})
export class RestorePasswordComponent implements OnInit {
  email: string = '';
  newPassword: string = '';
  repeatPassword: string = '';
  newPassToggle: boolean = false;
  repeatPassToggle: boolean = false;
  validPasswordPattern: boolean = true;
  validPasswordPatternTwo: boolean = true;
  disabledButton: boolean = true;

  successRequest: boolean = false;
  openModal: boolean = false;
  @ViewChild('myButton') myButton: ElementRef | undefined;

  // Icons
  iconCancel: string;
  iconEye: string;

  // Images
  imageMainLogo: string;

  constructor(
    private router: Router,
    private routerArg: ActivatedRoute,
    private _apiHttpService: ApiHttpService
  ) {
    this.iconCancel = AppMediaService.media.icons.options.cancel;
    this.iconEye = AppMediaService.media.icons.options.eye;
    this.imageMainLogo = AppMediaService.media.logos.mainLogo;
  }

  validatePassword() {
    const passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    this.validPasswordPattern = passwordPattern.test(this.newPassword);
    this.validPasswordPatternTwo =
      this.newPassword == this.repeatPassword && this.newPassword != '';
    if (this.validPasswordPattern && this.validPasswordPatternTwo) {
      this.disabledButton = false;
    } else {
      this.disabledButton = true;
    }
  }

  validatePasswordTwo() {
    this.validPasswordPatternTwo =
      this.newPassword == this.repeatPassword && this.newPassword != '';
    if (this.validPasswordPattern && this.validPasswordPatternTwo) {
      this.disabledButton = false;
    } else {
      this.disabledButton = true;
    }
  }

  ngOnInit() {
    this.routerArg.queryParams.subscribe((params) => {
      this.email = params['email'];
    });
  }

  signIn() {
    if (!this.disabledButton) {
      this.disabledButton = true;
      this.openModal = true;
      this._apiHttpService
        .post<TreebuLedgerResponseInterface>(
          `${environment.treebuCapital}/${environment.prefixBasic}/${environment.prefixTreebuLedgerUnauthenticated}/User/reset-password`,
          { email: this.email, newPassword: this.newPassword }
        )
        .subscribe((result) => {
          if (result.data) {
            this.successRequest = true;
            this.myButton!.nativeElement.click();
          }
        });
    }
  }

  redirectToLogin() {
    this.router.navigateByUrl('/auth/sign-in');
  }

  toggleNewPassword() {
    this.newPassToggle = !this.newPassToggle;
  }
  toggleRepeatPassword() {
    this.repeatPassToggle = !this.repeatPassToggle;
  }
}
