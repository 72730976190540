import { environment } from "src/environments/environment";

export class AppRoutesConfig {
  public static treebuHasura(uri: string): string {
    return `${environment.treebuHasura}/${environment.prefixTreebuHasura}/${uri}`;
  }

  public static treebuCapital(uri: string): string {
    return `${environment.treebuCapital}/${environment.prefixBasic}/${uri}`;
  }

  public static treebuCrypto(uri: string): string {
    return `${environment.treebuCrypto}/${environment.prefixTreeCrypto}/${uri}`;
  }

  public static treebuLedger(uri: string): string {
    return `${environment.treebuLedger}/${uri}`;
  }

  public static treebuEncrpyted(uri: string): string {
    return `${environment.treebuCapital}/${environment.prefixEncrypted}/${uri}`;
  }

  public static treebuUser(uri: string): string {
    return `${environment.treebuHasura}/${uri}`;
  }
}
