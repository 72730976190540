<div class="main-box pointer" (click)="openCloseSidebar()">
    <img [src]="openClose" alt="" class="image-sidebar" *ngIf="isOpened">
    <img [src]="closeOpen" alt="" class="image-sidebar" *ngIf="!isOpened">
  </div>
  <div class="d-flex flex-column align-items-start vh-100">
    <ul class="nav nav-pills flex-column mt-4 menu-list pointer">
      <li class="nav-item text-black my-2" *ngFor="let item of navigationItems">
        <ng-container *ngIf="item.children; else itemWithoutChildren">
          <div [ngClass]="{'flex justify-content-center': !isOpened}">
            <span class="nav-link fw-semibold"
                  [ngClass]="{
                    'text-center': !isOpened,
                    'text-start': isOpened,
                    'text-white': (hasActiveChild(item) && !isOpened) || (item.active && isOpened) || (!item.children && item.active),
                    'text-black': (!hasActiveChild(item) && !item.active) || (hasActiveChild(item) && !isOpened && !item.active)
                  }"
                  (click)="isActivedRoute(item)" [routerLink]="item.route" routerLinkActive="active"
                  data-bs-toggle="collapse" [attr.data-bs-target]="'#'+item.name">
              <img [src]="hasActiveChild(item) ? item.iconActive : (item.active ? item.iconActive : item.icon)" alt="{{ item.title }}" [ngClass]="{'me-2': isOpened, 'icon-white': hasActiveChild(item) || (item.active && isOpened) }" />
              <span *ngIf="isOpened">{{ item.title }}</span>
              <img *ngIf="isOpened && !item.active" [src]="rightArrow" alt="">
              <img *ngIf="isOpened && item.active" [src]="downArrow" alt="">
            </span>
          </div>
          <div id="{{item.name}}" class="collapse ms-3">
            <li class="nav-item text-black my-2" *ngFor="let child of item.children">
              <span class="nav-link text-start fw-semibold"
                    [ngClass]="{'text-white': child.active, 'text-black': !child.active}"
                    (click)="isActivedRoute(child)" [routerLink]="child.route" routerLinkActive="active">
                <img src="{{ child.active ? child.iconActive : child.icon }}" alt="{{ child.title }}" [ngClass]="{'me-2': isOpened}" width="15px" />
                <span *ngIf="isOpened">{{ child.title }}</span>
              </span>
            </li>
          </div>
        </ng-container>
        <ng-template #itemWithoutChildren>
          <span class="nav-link fw-semibold"
                [ngClass]="{
                  'text-center': !isOpened,
                  'text-start': isOpened,
                  'text-black': !item.active,
                  'text-white': item.active
                }"
                (click)="isActivedRoute(item)" [routerLink]="item.route" routerLinkActive="active">
            <img src="{{ item.active ? item.iconActive : item.icon }}" alt="{{ item.title }}" [ngClass]="{'me-2': isOpened}" />
            <span *ngIf="isOpened">{{ item.title }}</span>
          </span>
        </ng-template>
      </li>
    </ul>
  </div>
  