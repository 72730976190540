import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NavigationInterface } from 'src/app/core/navigation/navigation.interface';
import { treebuBackofficeNavigation } from './data';

@Injectable({
  providedIn: 'root'
})
export class NavigationMockApi {
  public getTreebuNavigation(): Observable<NavigationInterface[]> {
    return of(treebuBackofficeNavigation);
  }
}
