import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from '../admin/admin.component';
import { NavigationModule } from 'src/app/shared/components/navigation/navigation.module';

@NgModule({
  declarations: [
    AdminComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,

    // Project Modules
    NavigationModule,
  ]
})
export class AdminModule { }
