import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RestoreComponent } from './restore.component';
import { RestorePasswordComponent } from './pages/restore-password/restore-password.component';
import { RestoreValidateCodeComponent } from './pages/restore-validate-code/restore-validate-code.component';

const routes: Routes = [
  {
    path: '',
    component: RestoreComponent,
    children: [
      {
        path: 'password',
        component: RestorePasswordComponent
      },
      {
        path: 'validate-code',
        component: RestoreValidateCodeComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/restore/validate-code'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RestoreRoutingModule { }
