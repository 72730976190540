<div class="text-center">
    <img [src]="imageLogo" width="120">
</div>
<div class="text-center mt-3">
    <span class="fw-bold fs-5">Iniciar sesión</span>
</div>

<form [formGroup]="signInForm" (ngSubmit)="verifySignIn()">
    
    
    <div class="mt-4">
        <label for="email">
            <small>Correo electrónico</small>
        </label>
        <div class="input-group" [ngClass]="{'required-input' : signInForm.controls['email'].errors && signInForm.controls['email'].value != '',
                                             'input-control-filled': signInForm.controls['email'].valid}">
            <input  formControlName="email" 
                    class="input-control"
                    type="email" id="email"
                    placeholder="correo@ejemplo.com"
                    required >
        </div>
    </div>
    <div class="container">
        <li *ngIf="signInForm.controls['email'].invalid && signInForm.controls['email'].value != ''" class="input-text-warning">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-diamond-fill" viewBox="0 0 16 16">
                <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </svg>
            El correo es invalido         
        </li>
    </div>

    <div class="mt-2 mb-4">
        <label for="passwordField">
            <small>Contraseña</small>
        </label>

        <div class="input-group" [ngClass]="{'required-input' : signInForm.controls['password'].invalid && signInForm.controls['password'].value != '',
                                             'input-control-filled': signInForm.controls['password'].valid }">
            <input formControlName="password" 
                   type="{{ showPassword ? 'text' : 'password' }}" 
                   id="passwordField"            
                   class="input-control" 
                   placeholder="Ingresar" 
                   required >
                  
            <button class="btn-show-password" type="button" id="togglePassword" (click)="showPassword = !showPassword">
                <img *ngIf="!showPassword" [src]="iconEye" alt="">
                <img *ngIf="showPassword" [src]="iconCancel" alt="">
            </button>      
        </div>

        <div class="container">
            <li *ngIf="signInForm.get('password')?.hasError('required') && signInForm.controls['password'].value != ''" class="input-text-warning">
                Contraseña invalida
            </li>
            <li *ngIf="signInForm.get('password')?.hasError('invalidPassword')" class="input-text-warning">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-diamond-fill" viewBox="0 0 16 16">
                    <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                Contraseña invalida
            </li>
        </div>

        <div class="text-center mt-4">
            <button type="submit" class="btn-main" [disabled]="signInForm.invalid">Iniciar sesión</button>
            <!-- <main-button buttonText="Iniciar sesión" (sendEvent)="verifySignIn()"></main-button> -->
        </div>
    </div>
</form>