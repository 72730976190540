<div class="text-center">
    <img [src]="imageMainLogo" width="120">
</div>
<div class="text-center mt-3">
    <span class="fw-bold fs-5">Reestablecer contraseña</span>
</div>
<div class="text-center">
    <span class="suptitle-class">Crea una contraseña para ingresar</span>
</div>

<div class="mt-4">
    <label for="newPassword">
        <small>Nueva contraseña</small>
    </label>
    <div class="input-group border border-1 rounded-3">
        <input [(ngModel)]="newPassword" name="newPassword" id="newPassword"
            [attr.type]="!newPassToggle ? 'password': 'text'"
            class="form-control border border-1 border-black border-opacity-50" placeholder="••••••••" required
            (input)="validatePassword()" #passwordInput="ngModel">
        <button class="btn border-0 " type="button" (click)="toggleNewPassword()">
            <img *ngIf="!newPassToggle" [src]="iconEye" alt="">
            <img *ngIf="newPassToggle" [src]="iconCancel" alt="">
        </button>
    </div>
    <div *ngIf="validPasswordPattern === false" class="error-message">
        La contraseña debe Incluir letras, números, caracteres especiales.
        Combinar letras mayúsculas y minúsculas.
        No contener espacios en blanco.
    </div>
</div>

<div class="mt-2 mb-4">
    <label for="repeatPassword">
        <small>Confirmar nueva contraseña</small>
    </label>
    <div class="input-group border border-1 border-black border-opacity-50 rounded-3">
        <input [(ngModel)]="repeatPassword" name="repeatPassword" id="repeatPassword" class="form-control"
            [attr.type]="!repeatPassToggle ? 'password': 'text'" placeholder="••••••••" required
            (input)="validatePasswordTwo()">
        <button class="btn border-0 " type="button" (click)="toggleRepeatPassword()">
            <img *ngIf="!repeatPassToggle" [src]="iconEye" alt="">
            <img *ngIf="repeatPassToggle" [src]="iconCancel" alt="">
        </button>
    </div>
    <div *ngIf="validPasswordPatternTwo === false" class="error-message">
        Las contraseñas no coincide
    </div>
</div>

<div class="text-center mb-3">
    <button class="btn-main" #myButton [disabled]="disabledButton && !openModal" (click)="signIn()" [attr.data-bs-toggle]="openModal ? 'modal' : null"
        data-bs-target="#recoverPassword">Cambiar contraseña</button>
</div>


<!-- Modal -->
<div class="modal fade" id="recoverPassword" data-bs-backdrop="static" data-bs-keyboard="true" tabindex="-1"
    aria-labelledby="recoverPasswordLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content px-4">
            <div class="modal-body" *ngIf="!successRequest">
                <h1 class="modal-title fs-5 fw-bold text-center mb-4">
                    Ocurrió un error, por favor, intente más tarde.
                </h1>
                <button type="button" class="btn-main" data-bs-dismiss="modal" aria-label="Close">Cerrar</button>
            </div>
            <div class="modal-body" *ngIf="successRequest">
                <h1 class="modal-title fs-5 fw-bold text-center mb-4">
                    ¡El Cambio de contraseña fue exitoso!
                </h1>
                <button type="button" class="btn-main" (click)="redirectToLogin()" data-bs-dismiss="modal"
                    aria-label="Close">Ir a inicio de sesión</button>
            </div>
        </div>
    </div>
</div>