import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TableRowsObject } from 'src/app/core/objects/table-rows.object';

@Component({
  selector: 'show-rows',
  templateUrl: './show-rows.component.html'
})
export class ShowRowsComponent {

  getRows: number = 5;
  @Input() rowsFilter: Array<number> = TableRowsObject;
  @Output() rowsAmount: EventEmitter<number> = new EventEmitter<number>();

  sendEvent(): void {
    this.rowsAmount.emit(this.getRows);
  }
}
