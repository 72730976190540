<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content px-4 modal-content-layout">
        <div class="modal-header mt-3 border-0">
            <h1 class="modal-title fs-5 fw-bold" id="staticBackdropLabel" *ngIf="!emailSent">
                Cambiar contraseña
            </h1>
            <h1 class="modal-title fs-5 fw-bold" id="staticBackdropLabel" *ngIf="emailSent">
                Revisa tu correo electrónico
            </h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" *ngIf="!emailSent">
            <p>
                Escribe tu correo electrónico y te enviaremos un link para restablecer tu contraseña.
            </p>
            <form [formGroup]="signInForm" class="mt-4">
                <label for="email-recover">
                    <small>Correo electrónico</small>
                </label>
                <div class="input-group" [ngClass]="{'required-input' : signInForm.controls['email'].errors && signInForm.controls['email'].value != '',
                                                     'input-control-filled': signInForm.controls['email'].valid}">
                    <input  [(ngModel)]="email" 
                            name="email" type="email" id="email-recover"
                            class="input-control" 
                            placeholder="correo@ejemplo.com"
                            formControlName="email" 
                            (ngModelChange)="errorMessage = ''"
                            required>
                </div>
                <div class="container">
                    <li *ngIf="signInForm.controls['email'].invalid && signInForm.controls['email'].value != ''" class="input-text-warning">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-diamond-fill" viewBox="0 0 16 16">
                            <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                        </svg>
                        El correo es invalido         
                    </li>
                    <li *ngIf="errorMessage != ''" class="input-text-warning">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-diamond-fill" viewBox="0 0 16 16">
                            <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                        </svg>
                        Correo electronico no registrado
                    </li>
                </div>
            </form>
        </div>
        <div class="text-center mb-5 mt-2 mx-3" *ngIf="!emailSent">
            <main-button buttonText="Enviar" (sendEvent)="verifySendEmail()"></main-button>
        </div>
        <div class="modal-body" *ngIf="emailSent">
            <p>
                Esta acción requiere una verificación de correo.<br>
                Por favor revisa tu buzón de correo y sigue las instrucciones enviadas.<br>
                El correo fue enviado a: {{email}}
            </p>
        </div>
        <div class="text-center mb-5 mt-2" *ngIf="emailSent">
            <main-button buttonText="Entendido" data-bs-dismiss="modal" (sendEvent)="navigateResetPassword()"></main-button>
        </div>
    </div>
</div>