import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

import { AppMediaService } from 'src/app/core/media/app-media.service';
import { NavigationInterface } from 'src/app/core/navigation/navigation.interface';

@Component({
  styleUrls: ['./sidebar.component.css'],
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  currentRoute: string = '';
  navigationItems!: NavigationInterface[];

  downArrow: string;
  rightArrow: string;
  openClose: string = AppMediaService.media.icons.options.openClose;
  closeOpen: string = AppMediaService.media.icons.options.closeOpen;
  isOpened: boolean = true;

  constructor(private _activedRoute: ActivatedRoute, private _router: Router) {
    this.downArrow = AppMediaService.media.icons.navigation.arrows.downArrow;
    this.rightArrow = AppMediaService.media.icons.navigation.arrows.rightArrow;
  }

  ngOnInit(): void {
    this.navigationItems = this._activedRoute.snapshot.data['initialData'];
    this.loadActiveState();

    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute = event.urlAfterRedirects;
        this.updateNavigationItems();
      });
  }

  saveActiveState() {
    const activeItem = this.navigationItems.find(item => item.active);
    if (activeItem) {
      localStorage.setItem('activeRoute', activeItem.route);
    }
  }

  loadActiveState() {
    const activeRoute = localStorage.getItem('activeRoute');
    if (activeRoute) {
      this.navigationItems.forEach(item => {
        item.active = (item.route === activeRoute);
        if (item.children) {
          item.children.forEach(child => {
            child.active = (child.route === activeRoute);
          });
        }
      });
    }
  }

  updateNavigationItems() {
    this.navigationItems.forEach(item => {
      item.active = this.currentRoute === `/admin/${item.route}`;
      if (item.children) {
        item.children.forEach(child => {
          child.active = this.currentRoute === `/admin/${child.route}`;
        });
      }
    });
    this.saveActiveState();
  }

  isActivedRoute(navigation: NavigationInterface) {
    this.navigationItems.forEach(item => {
      item.active = false;
      if (item.children) {
        item.children.forEach(child => child.active = false);
      }
    });
    navigation.active = true;
    this.saveActiveState();
  }

  toggleItem(navigation: NavigationInterface) {
    navigation.active = !navigation.active;
  }

  openCloseSidebar() {
    this.isOpened = !this.isOpened;
  }

  hasActiveChild(item: any): boolean {
    if (item.children) {
      for (const child of item.children) {
        if (child.active) {
          return true;
        }
      }
    }
    return false;
  }
}
