import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { excludedEndpoints } from '../constants/endpoints.constants';

@Injectable()
export class RequestHttpInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Check if the request URL is in the excludedEndpoints
        if (this.isExcludedEndpoint(request.url)) {
            return next.handle(request); // Do not modify the request
        }

        // Get the access token from localStorage
        const accessToken = localStorage.getItem('accessToken') ?? '';

        // Clone the request and add the headers
        const modifiedRequest = request.clone({
            setHeaders: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json; charset=utf-8'
            }
        });

        // Continue with the modified request
        return next.handle(modifiedRequest);
    }

    private isExcludedEndpoint(url: string): boolean {
        return excludedEndpoints.some(endpoint => url.includes(endpoint));
    }
}
