<div class="assign-body">
    <div class="container vh-100 d-flex justify-content-center align-items-center">
        <div class="card p-5 col-sm-12 col-md-6 col-lg-4 rounded-4 shadow">



            <form [formGroup]="assignPasswordForm" (ngSubmit)="verifyPassword()">
                <div class="text-center">
                    <img [src]="imageMainLogo" width="120">
                </div>
                <div class="text-center mt-3">
                    <span class="fw-bold fs-5">Asignar contraseña</span>
                </div>
                <div class="text-center">
                    <span class="suptitle-class">Crea una contraseña para ingresar</span>
                </div>

                <div class="mt-4">
                    <label for="password">
                        <small>Nueva contraseña</small>
                    </label>
                    <div class="input-group border border-1 rounded-3">
                        <input formControlName="password" id="password" type="{{ newPassToggle ? 'text' : 'password' }}"
                            class="form-control border border-1 border-black border-opacity-50" placeholder="Ingresar"
                            required>
                        <button class="btn border-0 " type="button" (click)="newPassToggle = !newPassToggle">
                            <img *ngIf="!newPassToggle" [src]="iconEye" alt="">
                            <img *ngIf="newPassToggle" [src]="iconCancel" alt="">
                        </button>
                    </div>
                </div>

                <div class="mt-2 mb-4">
                    <label for="verifyPassword">
                        <small>Confirmar nueva contraseña</small>
                    </label>
                    <div class="input-group border border-1 border-black border-opacity-50 rounded-3">
                        <input formControlName="verifyPassword" id="verifyPassword" class="form-control"
                            type="{{ repeatPassToggle ? 'text' : 'password' }}" placeholder="Ingresar" required>
                        <button class="btn border-0 " type="button" (click)="repeatPassToggle = !repeatPassToggle">
                            <img *ngIf="!repeatPassToggle" [src]="iconEye" alt="">
                            <img *ngIf="repeatPassToggle" [src]="iconCancel" alt="">
                        </button>
                    </div>
                </div>

                <div class="text-center mb-3">
                    <button type="submit" class="btn-main" [disabled]="assignPasswordForm.invalid">Iniciar sesión</button>
                </div>
            </form>

            <div class="container">
                <li *ngIf="assignPasswordForm.get('password')?.hasError('required')" class="text-danger">
                    La contraseña es requerida.
                </li>
                <li *ngIf="assignPasswordForm.get('password')?.hasError('minlength')" class="text-danger">
                    La contraseña debe tener al menos 8 caracteres.
                </li>
                <li *ngIf="assignPasswordForm.get('password')?.hasError('maxlength')" class="text-danger">
                    La contraseña no puede tener más de 15 caracteres.
                </li>
                <li *ngIf="assignPasswordForm.get('password')?.hasError('invalidPassword')" class="text-danger">
                    La contraseña debe Incluir letras, números, caracteres especiales.
                    Combinar letras mayúsculas y minúsculas.
                    No contener espacios en blanco.
                </li>
            </div>

        </div>
    </div>
</div>

<!-- Modal -->
<div class="assing-footer" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
    <p>© 2023 Todos los derechos reservados</p>
</div>