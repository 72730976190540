import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainButtonComponent } from './components/Inputs/main-button/main-button.component';
import { NavigationModule } from './components/navigation/navigation.module';
import { PriceBoxComponent } from './components/price-box/price-box.component';
import { SearchComponent } from './components/Inputs/search/search.component';
import { OutlineButtonComponent } from './components/Inputs/outline-button/outline-button.component';
import { ShowRowsComponent } from './components/Inputs/show-rows/show-rows.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { CryptoPriceBoxComponent } from './components/crypto-price-box/crypto-price-box.component';





@NgModule({
  declarations: [
    MainButtonComponent,
    PriceBoxComponent,
    CryptoPriceBoxComponent,
    SearchComponent,
    OutlineButtonComponent,
    ShowRowsComponent,
    PaginatorComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NavigationModule,
  ],
  exports: [
    MainButtonComponent,
    PriceBoxComponent,
    CryptoPriceBoxComponent,
    SearchComponent,
    OutlineButtonComponent,
    ShowRowsComponent,
    PaginatorComponent,

    // Modules
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class SharedModule { }
