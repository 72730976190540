import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { NavigationService } from './core/navigation/navigation.service';
import { NavigationInterface } from './core/navigation/navigation.interface';

@Injectable({
  providedIn: 'root'
})
export class AppResolver implements Resolve<NavigationInterface[]> {
  constructor(private _navigationService: NavigationService) { }

  resolve(): Observable<NavigationInterface[]> {
    return this._navigationService.getNavigation();
  }
}
