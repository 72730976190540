import { Component, Input, OnInit } from '@angular/core';
import { CurrencyInterface } from 'src/app/core/interfaces/currency-interface';

@Component({
  selector: 'crypto-price-box',
  templateUrl: './crypto-price-box.component.html',
})
export class CryptoPriceBoxComponent implements OnInit {
  @Input() title: string = 'Dinero';
  @Input() amount: number = 15000000;
  @Input() currency!: CurrencyInterface;

  constructor() {}

  ngOnInit(): void {
  }
}
