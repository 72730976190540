import { Component, OnInit } from '@angular/core';
import { TimerService } from './core/helpers/timer-services.service';
import { AuthService } from './core/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private _timerService: TimerService,
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && accessToken !== 'null') {
      this._timerService.startTimer(environment.timerRefreshToken, () => {
        this._authService.refreshFirebaseToken();
      });
    }
  }
}
