import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiHttpService {

  constructor(private _httpClient: HttpClient) { }

  get<T>(url: string): Observable<T> {
    return this._httpClient.get<T>(url)
      .pipe(catchError(this.handleError<T>));
  }

  post<T>(url: string, data: any = {}): Observable<T> {
    return this._httpClient.post<T>(url, data)
      .pipe(catchError(this.handleError<T>));
  }

  put(url: string, data: any): Observable<any> {
    return this._httpClient.put(url, data)
      .pipe(catchError(this.handleError));
  }

  delete(url: string): Observable<any> {
    return this._httpClient.delete(url)
      .pipe(catchError(this.handleError));
  }

  handleError<T>(err: any): Observable<T> {
    let errorMessage = 'There was an error, please, try it later.';
    // Swal.fire({
    //   title: 'Ocurrió un error',
    //   text: err.error.message,
    //   icon: 'error',
    //   confirmButtonText: 'Cerrar',
    // });
    return throwError(() => errorMessage);
  };


  postEncrypt<T>(url: string, data: any = {}, responseType = 'json'): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = responseType === 'json' ? { headers } : { headers, responseType: 'text' as 'json' };
    return this._httpClient.post<any>(url, data, options).pipe(
      catchError(this.handleError<T>)
    );
  }
}
