import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { NavigationInterface } from 'src/app/core/navigation/navigation.interface';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit {
  currentRoute: string = '';
  navigationItems!: NavigationInterface[];

  constructor(
    private _activedRoute: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.navigationItems = this._activedRoute.snapshot.data['initialData'];
  }

  updateNavigationItems(navigation: NavigationInterface) {
    navigation.active = this.currentRoute == `/admin/${navigation.route}`;
  }

  ValidateCurrentRoute(navigation: NavigationInterface) {
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.currentRoute = event.urlAfterRedirects;
      this.updateNavigationItems(navigation);
    });
  }

  isActivedRoute(navigation: NavigationInterface) {
    this.ValidateCurrentRoute(navigation);
  }
}
