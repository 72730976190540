import { Injectable } from '@angular/core';
import { AppMediaConfig } from './app-media.config';

@Injectable({
  providedIn: 'root'
})
export class AppMediaService {

  public static media = {
    logos: {
      mainLogo: AppMediaConfig.logoSource('main-logo.png'),
    },
    backgrounds: {},
    images: {
      user: AppMediaConfig.imagesSource('user.jpg'),
    },
    icons: {
      navigation: {
        arrows: {
          downArrow: AppMediaConfig.iconsSource('menu/down-arrow.png'),
          rightArrow: AppMediaConfig.iconsSource('menu/right-arrow.png'),
        },
        transaction: {
          basic: AppMediaConfig.iconsSource('menu/menu-transaction.png'),
          active: AppMediaConfig.iconsSource('menu/menu-transaction-active.png'),
          black: AppMediaConfig.iconsSource('menu/menu-transaction-black.png'),
        },
        identityValidation: {
          basic: AppMediaConfig.iconsSource('menu/menu-identity.png'),
          active: AppMediaConfig.iconsSource('menu/menu-identity-active.png'),
          black: AppMediaConfig.iconsSource('menu/menu-identity-black.png'),
        },
        exchange: {
          basic: AppMediaConfig.iconsSource('menu/menu-exchange.png'),
          active: AppMediaConfig.iconsSource('menu/menu-exchange-active.png'),
          black: AppMediaConfig.iconsSource('menu/menu-exchange-black.png'),
        },
        commission: {
          basic: AppMediaConfig.iconsSource('menu/menu-commission.png'),
          active: AppMediaConfig.iconsSource('menu/menu-commission-active.png'),
          black: AppMediaConfig.iconsSource('menu/menu-commission-black.png'),
        },
        token: {
          basic: AppMediaConfig.iconsSource('menu/menu-token.png'),
          active: AppMediaConfig.iconsSource('menu/menu-token-active.png'),
          black: AppMediaConfig.iconsSource('menu/menu-token-black.png'),
        },
        manageUser: {
          basic: AppMediaConfig.iconsSource('menu/menu-user.png'),
          active: AppMediaConfig.iconsSource('menu/menu-user-active.png'),
          black: AppMediaConfig.iconsSource('menu/menu-user-black.png'),
        },
      },
      options: {
        cancel: AppMediaConfig.iconsSource('options/cancel.png'),
        save: AppMediaConfig.iconsSource('options/save.png'),
        edit: AppMediaConfig.iconsSource('options/edit.png'),
        add: AppMediaConfig.iconsSource('options/plus-color.png'),
        bell: AppMediaConfig.iconsSource('options/bell-notification.png'),
        arrowDown: AppMediaConfig.iconsSource('options/arrow-down.png'),
        download: AppMediaConfig.iconsSource('options/download.png'),
        sort: AppMediaConfig.iconsSource('options/column-sorting.png'),
        eye: AppMediaConfig.iconsSource('options/eye.png'),
        search: AppMediaConfig.iconsSource('options/search.png'),
        openClose: AppMediaConfig.iconsSource('options/open-close.png'),
        closeOpen: AppMediaConfig.iconsSource('options/close-open.png'),
      }
    }
  }
}
