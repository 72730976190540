import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppMediaService } from 'src/app/core/media/app-media.service';

@Component({
  selector: 'outline-button',
  templateUrl: './outline-button.component.html'
})
export class OutlineButtonComponent {
  @Input() buttonName: string = 'Mostrar';
  @Input() iconDownload: string;
  @Output() sendEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
    this.iconDownload = AppMediaService.media.icons.options.download;
  }

  emitEvent(): void {
    this.sendEvent.emit();
  }
}
