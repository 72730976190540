import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';

import { FormsModule } from '@angular/forms';
import { AssignComponent } from './assign.component';
import { AssignRoutingModule } from './assign-routing.module';


@NgModule({
  declarations: [
    AssignComponent,
  ],
  imports: [
    CommonModule,
    AssignRoutingModule,
    FormsModule,

    // Project's Module
    SharedModule
  ]
})
export class AssignModule { }
