import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    if (
      !localStorage.getItem('accessToken') ||
      localStorage.getItem('accessToken') == 'null'
    ) {
      return true;
    }

    this.router.navigate(['/admin/transactions']);
    return false;
  }
}
