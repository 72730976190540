import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ApiHttpService } from 'src/app/core/http/api-http.service';
import { TreebuLedgerResponseInterface } from 'src/app/core/interfaces/treebu-token.interface';
import { AppMediaService } from 'src/app/core/media/app-media.service';
import { NotificactionService } from 'src/app/core/notifications/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-restore-validate-code',
  templateUrl: './restore-validate-code.component.html',
  styleUrls: ['./restore-validate-code.component.css'],
})
export class RestoreValidateCodeComponent implements OnInit {
  code: string[] = ['', '', '', ''];
  codeFull: boolean = false;
  email: string = '';
  invalidCode: boolean = false;

  // Images
  imageMainLogo: string;

  constructor(
    private _apiHttpService: ApiHttpService,
    private routerArg: ActivatedRoute,
    private router: Router
  ) {
    this.imageMainLogo = AppMediaService.media.logos.mainLogo;
  }

  ngOnInit() {
    this.invalidCode = false;
    this.routerArg.queryParams.subscribe((params) => {
      this.email = params['email'];
    });
  }

  hideEmail(email: string): string {
    const parts = email.split('@');
    if (parts.length === 2) {
      const username = parts[0];
      const domain = parts[1];
      const hiddenCharacters = username.length - 1;

      const hiddenEmail =
        username.charAt(0) + '*'.repeat(hiddenCharacters) + '@' + domain;
      return hiddenEmail;
    }
    return email;
  }

  onCodeInput(event: any, index: number) {
    const inputValue = event.target.value;
    if (/^\d$/.test(inputValue)) {
      this.code[index] = inputValue;
      if (index < this.code.length - 1) {
        const nextInput = document.getElementById(
          'field' + (index + 1)
        ) as HTMLInputElement;
        if (nextInput) {
          nextInput.focus();
        }
      }
      if (index > 2) {
        this.codeFull = true;
      } else {
        this.codeFull = false;
      }
    }
  }

  validateCode() {
    this._apiHttpService
      .get<TreebuLedgerResponseInterface>(
        `${environment.treebuCapital}/${environment.prefixBasic}/${environment.prefixTreebuLedgerUnauthenticated}/Otp/validate?email=${
          this.email
        }&code=${this.code.join('')}`
      )
      .subscribe(
        (result) => {
          if (!result.data) {
            NotificactionService.Swal.fire({
              icon: 'error',
              title: 'Código incorrecto.',
              text: 'Por favor, valide el código ingresado.',
            });
            return;
          }
          this.router.navigate(['/restore/password'], {
            queryParams: { email: this.email },
          });
        },
        (err) => {
          this.invalidCode = true;
        }
      );
  }

  backLogin() {
    this.router.navigate(['/auth/sign-in']);
  }

  resendCode() {
    this._apiHttpService
      .post<TreebuLedgerResponseInterface>(
        `${environment.treebuCapital}/${environment.prefixBasic}/${environment.prefixTreebuLedgerUnauthenticated}/AwsSES/send-recover-password`,
        { email: this.email }
      )
      .subscribe(
        (result) => {
          if (result.data == 'Email sent successfully') {
            NotificactionService.Toast.fire({
              icon: 'success',
              title: 'El código se ha enviado correctamente.',
            });
          } else {
            NotificactionService.Toast.fire({
              icon: 'error',
              title: 'Error al enviar el código.',
              text: 'Ocurrió un error al enviar su código de verficiación, por favor, intente más tarde.',
            });
          }
        },
        (err) =>
          NotificactionService.Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: err,
          })
      );
  }
}
