import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationInterface } from './navigation.interface';
import { NavigationMockApi } from '../../mock-api/navigation/api';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private _navigationMockApi: NavigationMockApi) { }

  private _navigation: BehaviorSubject<NavigationInterface[]> = new BehaviorSubject<NavigationInterface[]>([]);

  get navigation$(): Observable<NavigationInterface[]> {
    return this._navigation.asObservable();
  }

  getNavigation(): Observable<NavigationInterface[]> {
    return this._navigationMockApi.getTreebuNavigation().pipe(
      tap((navigation: NavigationInterface[]) => {
        this._navigation.next(navigation);
      })
    );
  }
}
