import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'transactions',
        loadChildren: () => import('./pages/transaction/transaction.module').then(m => m.TransactionModule)
      },
      {
        path: 'identity-validation',
        loadChildren: () => import('./pages/identity-validation/identity-validation.module').then(m => m.IdentityValidationModule)
      },
      {
        path: 'exchange-rates',
        loadChildren: () => import('./pages/exchange-rate/exchange-rate.module').then(m => m.ExchangeRateModule)
      },
      {
        path: 'commissions-fiat',
        loadChildren: () => import('./pages/commission/commission.module').then(m => m.CommissionModule)
      },
      {
        path: 'treebu-tokens',
        loadChildren: () => import('./pages/treebu-token/treebu-token.module').then(m => m.TreebuTokenModule)
      },
      {
        path: 'manage-users',
        loadChildren: () => import('./pages/manage-user/manage-user.module').then(m => m.ManageUserModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
