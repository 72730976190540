import { Injectable } from '@angular/core';
import { AppRoutesConfig } from './app-routes.config';

@Injectable({
  providedIn: 'root',
})
export class AppRoutesService {
  public static util = {
    decryptObjects: AppRoutesConfig.treebuEncrpyted('decrypt-objects'),
    encryptObjects: AppRoutesConfig.treebuEncrpyted('encrypt')
  }
  
  public static auth = {
    signIn: AppRoutesConfig.treebuHasura('auth/sign-in'),
    signOut: AppRoutesConfig.treebuHasura('auth/sign-out'),
    signUp: AppRoutesConfig.treebuCapital(
      'treebu-ledger/v1/User/create-user-backoffice'
    ),
    activateUser: AppRoutesConfig.treebuCapital(
      'treebu-ledger/v1/AwsSES/send-account-activation'
    ),
    setPassword: AppRoutesConfig.treebuCapital(
      'treebu-ledger-unauthenticated/v1/User/create-user-backoffice-password'
    ),
  };

  public static admin = {
    commissions: {
      list: AppRoutesConfig.treebuHasura('commissions/get-parametrization'),
      update: AppRoutesConfig.treebuHasura('commissions/update-parametrization'),
      crypto: {
        list: AppRoutesConfig.treebuCrypto('EntryType'),
        update: AppRoutesConfig.treebuCrypto('EntryType'),
      }
    },
    currencies: {
      list: AppRoutesConfig.treebuHasura('currencies'),
      crypto: {
        list: AppRoutesConfig.treebuHasura('crypto/currency'),
      },
    },
    transactions: {
      list: AppRoutesConfig.treebuHasura('transactionlistbycurrencypaginated'),
      download: AppRoutesConfig.treebuHasura('transactionlistbycurrency'),
      listTotals: AppRoutesConfig.treebuHasura('transactiontotalsbycurrency'),
      crypto: {
        list: (selectedCurrentId:number, rows:number, offset:number, filter:string) =>
          `${AppRoutesConfig.treebuHasura('cryptotransactionlistbycurrencypaginated')}?currencyId=${selectedCurrentId}&limit=${rows}&offset=${offset}&orderBy=desc&filter=%|${filter}%`,
        listAll: (rows: number = 10, offset: number = 0, filter: string = '') =>
          `${AppRoutesConfig.treebuHasura('cryptotransactionlistbycurrencypaginatedwithall')}?limit=${rows}&offset=${offset}&orderBy=desc&filter=%|${filter}%`,
        download: (selectedCurrentId: number) =>
          `${AppRoutesConfig.treebuHasura('cryptotransactionlistbycurrency')}?currencyId=${selectedCurrentId}`,
        listTotals: (selectedCurrentId: number, filter: string) =>
          `${AppRoutesConfig.treebuHasura('cryptotransactiontotalsbycurrency')}?currencyId=${selectedCurrentId}&filter=%|${filter}%`,
      },
    },
    treebuTokens: {
      main: AppRoutesConfig.treebuHasura('tokens/get-parameterization'),
      update: AppRoutesConfig.treebuHasura('tokens/update-parametrization'),
      byUser: {
        list: AppRoutesConfig.treebuHasura(
          'posttreebutokenstotalsbyuserpaginated'
        ),
      },
    },
    exchangeRate: {
      list: AppRoutesConfig.treebuHasura('getexchangeratelist'),
      update: AppRoutesConfig.treebuHasura('updateexchangerate'),
      updateLedger: AppRoutesConfig.treebuCapital(
        'treebu-ledger/v1/ExchangeRate/bo'
      ),
      crypto: {
        list: AppRoutesConfig.treebuCrypto('ExchangeRate'),
        update: AppRoutesConfig.treebuCrypto('ExchangeRate'),
      },
    },
    identityValidation: {
      list: AppRoutesConfig.treebuHasura('postallcompanies'),
      update: AppRoutesConfig.treebuHasura('updatecompanystatusvalidation'),
      listTotals: AppRoutesConfig.treebuHasura('getallcompaniestotals'),
      createWallet: (userId: number) =>
        AppRoutesConfig.treebuLedger(
          `User/${userId}/wallet?password=Tr33buCap1tal`
        ),
      updateMetamapStatus: AppRoutesConfig.treebuUser('api/treebu-user/v1/MetaMap/verifications/status'),
    },
    manageUser: {
      list: AppRoutesConfig.treebuHasura('postbouserslistpaginated'),
      totalList: AppRoutesConfig.treebuHasura('getbouserslisttotals'),
      update: AppRoutesConfig.treebuUser('api/treebu-user/v1/User/update-bo-user'),
    },
  };
}
