<nav aria-label="Page navigation example">
  <ul class="pagination justify-content-center" role="button">
    <li class="page-item" *ngIf="config.currentPage > 1" [ngClass]="{'disabled': config.currentPage <= 1 }">
      <a class="page-link" (click)="pageChange.emit(config.currentPage - 1)">
        <span aria-hidden="true">&laquo;</span>
      </a>
    </li>

    <li class="page-item" *ngFor="let page of getPagesToShow()"
      [ngClass]="{ active: page === config.currentPage}">
      <a class="page-link fw-bold" (click)="pageChange.emit(page)">{{ page }}</a>
    </li>

    <li class="page-item" *ngIf="config.currentPage < totalPages">
      <a class="page-link" (click)="pageChange.emit(config.currentPage + 1)">
        <span aria-hidden="true">&raquo;</span>
      </a>
    </li>
  </ul>
</nav>
