import { AppMediaService } from 'src/app/core/media/app-media.service';
import { NavigationInterface } from 'src/app/core/navigation/navigation.interface';

export const treebuBackofficeNavigation: NavigationInterface[] = [
  {
    name: 'transactions',
    title: 'Transacciones',
    route: 'transactions',
    icon: AppMediaService.media.icons.navigation.transaction.basic,
    iconActive: AppMediaService.media.icons.navigation.transaction.active,
    iconBlack: AppMediaService.media.icons.navigation.transaction.black,
    active: false,
    children: [
      {
        name: 'crypto_transactions',
        title: 'Transacciones Cripto',
        route: 'transactions/crypto-transactions',
        icon: AppMediaService.media.icons.navigation.transaction.basic,
        iconActive: AppMediaService.media.icons.navigation.transaction.active,
        iconBlack: AppMediaService.media.icons.navigation.transaction.black,
        active: false,
      },
    ],
  },
  {
    name: 'identity_validation',
    title: 'Validación de identidad',
    route: 'identity-validation',
    icon: AppMediaService.media.icons.navigation.identityValidation.basic,
    iconActive: AppMediaService.media.icons.navigation.identityValidation.active,
    iconBlack: AppMediaService.media.icons.navigation.identityValidation.black,
    active: false,
  },
  {
    name: 'exchange_rates',
    title: 'Tasas de cambio',
    route: 'exchange-rates',
    icon: AppMediaService.media.icons.navigation.exchange.basic,
    iconActive: AppMediaService.media.icons.navigation.exchange.active,
    iconBlack: AppMediaService.media.icons.navigation.exchange.black,
    active: false,
    children: [
      {
        name: 'crypto-exchange-rates',
        title: 'Tasas de cambio Cripto',
        route: 'exchange-rates/crypto',
        icon: AppMediaService.media.icons.navigation.exchange.basic,
        iconActive: AppMediaService.media.icons.navigation.exchange.active,
        iconBlack: AppMediaService.media.icons.navigation.exchange.black,
        active: false,
      },
    ],
  },
  {
    name: 'commissions_fiat',
    title: 'Comisiones Fiat',
    route: 'commissions-fiat',
    icon: AppMediaService.media.icons.navigation.commission.basic,
    iconActive: AppMediaService.media.icons.navigation.commission.active,
    iconBlack: AppMediaService.media.icons.navigation.commission.black,
    active: false,
    children: [
      {
        name: 'commissions_crypto',
        title: 'Comisiones Cripto',
        route: 'commissions-fiat/commissions-crypto',
        icon: AppMediaService.media.icons.navigation.commission.basic,
        iconActive: AppMediaService.media.icons.navigation.commission.active,
        iconBlack: AppMediaService.media.icons.navigation.commission.black,
        active: false,
      },
    ],
  },
  {
    name: 'treebu_tokens',
    title: 'Treebu Tokens',
    route: 'treebu-tokens',
    icon: AppMediaService.media.icons.navigation.token.basic,
    iconActive: AppMediaService.media.icons.navigation.token.active,
    iconBlack: AppMediaService.media.icons.navigation.token.black,
    active: false,
    children: [
      {
        name: 'token_by_user',
        title: 'Tokens por usuario',
        route: 'treebu-tokens/by-user',
        icon: AppMediaService.media.icons.navigation.token.basic,
        iconActive: AppMediaService.media.icons.navigation.token.active,
        iconBlack: AppMediaService.media.icons.navigation.token.black,
        active: false,
      },
    ],
  },
  {
    name: 'manage_users',
    title: 'Administrar usuario',
    route: 'manage-users',
    icon: AppMediaService.media.icons.navigation.manageUser.basic,
    iconActive: AppMediaService.media.icons.navigation.manageUser.active,
    iconBlack: AppMediaService.media.icons.navigation.manageUser.black,
    active: false,
  },
];
