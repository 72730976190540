import { Component, Input, Output, EventEmitter } from '@angular/core';

import { PaginationInterface } from 'src/app/core/interfaces/pagination.interface';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent {
  @Input() config: PaginationInterface = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0
  };
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  totalPages: number = 0;

  getPagesToShow(): number[] {
    const pagesToShow = [];
    this.totalPages = Math.ceil(this.config.totalItems / this.config.itemsPerPage);
    const totalPagesToShow = Math.min(5, this.totalPages);

    const halfTotalPagesToShow = Math.floor(totalPagesToShow / 2);
    const startPage = Math.max(this.config.currentPage - halfTotalPagesToShow, 1);
    const endPage = Math.min(startPage + totalPagesToShow - 1, this.totalPages);

    for (let page = startPage; page <= endPage; page++) {
      pagesToShow.push(page);
    }

    return pagesToShow;
  }
}
