<div class="assign-body">
    <div class="container vh-100 d-flex justify-content-center align-items-center">
        <div class="card p-5 col-sm-12 col-md-6 col-lg-4 rounded-4 shadow">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="assing-footer" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
    <p>© 2023  Todos los derechos reservados</p>
</div>